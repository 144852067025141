import { graphql } from "gatsby"
import { FluidObject } from "gatsby-image"
import { sampleSize } from "lodash"
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { MediaQuerySSR } from "react-responsive-ssr"
import Slider from "react-slick"

import SEO from "../../components/common/seo"
import Ticker from "../../components/common/ticker"
import TickerStyle from "../../components/common/ticker.style"
import MapBox from "../../components/explore/map"
import {
  MapRetailer,
  ParkingLot,
  TransitStation,
} from "../../components/explore/map.client"
import {
  ParkingMapCard,
  RetailerMapCard,
  StationMapCard,
} from "../../components/explore/mapCard"
import Layout from "../../components/layout/layout"
import { AutoLink } from "../../components/link"
import { correctDateFormatWrapper, open } from "../../services/retailerService"
import {
  ApiRetailer,
  HoursProps,
  isRetailer,
  isStation,
  IYouMayAlsoLike,
  RetailerTickerCardProps,
  SimpleRetailer,
  sortRetailers,
  TypeToDisplay,
} from "../../services/retailersService"
import RichSnippet from "../../components/common/richSnippet"
import { CTA } from "../../components/common/cta"
import { Media } from "../../Media"
import { scrollToRef } from "../../services/scrollService"
import { COLORS, MOBILE_MAX_WIDTH } from "../../styles"
import RetailerHeader from "./header"
import Style from "./retailer.style"

interface Props {
  data: {
    contentfulRetailer: ApiRetailer & {
      about: {
        about: string
        childMarkdownRemark: {
          html: string
        }
      }
      centralImage: {
        title: string
        fluid: FluidObject
      }
      metaDescription?: string
      keywords?: string
      sundayOpeningHours: string
      mondayOpeningHours: string
      tuesdayOpeningHours: string
      wednesdayOpeningHours: string
      thursdayOpeningHours: string
      fridayOpeningHours: string
      saturdayOpeningHours: string
      externalLink: string
      instagramLink: string
      carouselImages: {
        id: string
        title: string
        fluid: FluidObject
      }[]
      video: {
        file: {
          url: string
          contentType: string
        }
      }
      onlyInFifth: boolean
      description: {
        description: string
      } | null
      comment?: {
        childMarkdownRemark: {
          html: string
        }
      }
      commentsAuthor?: string
      nearbyRecommendations: ApiRetailer[] | null
      transitStations: TransitStation[]
    }
    allContentfulRetailer: {
      edges: {
        node: ApiRetailer
      }[]
    }
    allContentfulItinerary: {
      edges: {
        node: {
          title: string
          slug: string
          image: {
            title: string
            fluid: FluidObject
          }
        }
      }[]
    }
    allContentfulParkingLot: {
      edges: {
        node: ParkingLot
      }[]
    }
  }
}

const DaysList = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
]

const HoursSection: FunctionComponent<HoursProps> = ({
  sundayOpeningHours,
  mondayOpeningHours,
  tuesdayOpeningHours,
  wednesdayOpeningHours,
  thursdayOpeningHours,
  fridayOpeningHours,
  saturdayOpeningHours,
}) => {
  const daysMap = [
    mondayOpeningHours,
    tuesdayOpeningHours,
    wednesdayOpeningHours,
    thursdayOpeningHours,
    fridayOpeningHours,
    saturdayOpeningHours,
    sundayOpeningHours,
  ]
  const daysHoursMap = []
  let i: number
  for (i = 0; i < 7; i++) {
    daysHoursMap.push({ key: DaysList[i], value: daysMap[i] })
  }

  const equalToMonday = daysMap.map(function(elem) {
    return elem === mondayOpeningHours ? 1 : 0
  })
  const areEqual = equalToMonday.every(elem => elem === 1)
  //Case A : all days have the same hours
  if (areEqual) {
    if (mondayOpeningHours == "24/7") {
      return <Style.Body.Row>Open 24 Hours, 7 Days a week.</Style.Body.Row>
    } else {
      return (
        <Style.Body.Row>
          <div>Everyday</div>
          {mondayOpeningHours}
        </Style.Body.Row>
      )
    }
  } else {
    return (
      <Style.Body.Day>
        {daysHoursMap.map(elem => (
          <Style.Body.Row key={elem.key}>
            <div>{elem.key}</div>
            <div>{correctDateFormatWrapper(elem.value)}</div>
          </Style.Body.Row>
        ))}
      </Style.Body.Day>
    )
  }
}

const NextArrow: FunctionComponent = (props: any) => {
  const { className, style, onClick } = props
  const isMobile =
    typeof window !== "undefined" && window.innerWidth <= MOBILE_MAX_WIDTH
  return (
    <div
      className={className}
      style={{
        ...style,
        display: isMobile ? "none" : "block",
        width: "54px",
        height: "54px",
        backgroundColor: "#083025",
      }}
      onClick={onClick}
    >
      <Style.CarouselNextArrow />
    </div>
  )
}

const PrevArrow: FunctionComponent = (props: any) => {
  const { className, style, onClick } = props
  const isMobile =
    typeof window !== "undefined" && window.innerWidth <= MOBILE_MAX_WIDTH
  return (
    <div
      className={className}
      style={{
        ...style,
        display: isMobile ? "none" : "block",
        width: "50px",
        height: "50px",
        backgroundColor: "#083025",
      }}
      onClick={onClick}
    >
      <Style.CarouselPrevArrow />
    </div>
  )
}

const RetailerTickerCard: FunctionComponent<RetailerTickerCardProps> = ({
  retailer,
  category,
  address,
  slug,
}) => {
  return (
    <Style.AlsoLikeTicker.Element>
      <Style.AlsoLikeTicker.Link to={`/${slug}`}>
        <TickerStyle.BottomCarouselImages.HighlightSpan />
        <Style.AlsoLikeTicker.ImageHolder>
          <TickerStyle.BottomCarouselImages.TickerImage
            fluid={retailer.heroImage.fluid}
            alt={retailer.heroImage.title}
          />
          <Style.AlsoLikeTicker.Address>
            {address.map((section: string) => (
              <Style.AlsoLikeTicker.AddressElement key={section}>
                {section.split("").map((str, index) => (
                  <div key={index + str}>{str}</div>
                ))}
              </Style.AlsoLikeTicker.AddressElement>
            ))}
          </Style.AlsoLikeTicker.Address>
        </Style.AlsoLikeTicker.ImageHolder>
        <Style.AlsoLikeTicker.Category>
          {category}
        </Style.AlsoLikeTicker.Category>
        <Style.AlsoLikeTicker.Name>{retailer.name}</Style.AlsoLikeTicker.Name>
        <CTA as="div" id="ctaId">
          Learn More
        </CTA>
      </Style.AlsoLikeTicker.Link>
    </Style.AlsoLikeTicker.Element>
  )
}

const Retailer: FunctionComponent<Props> = ({ data }) => {
  const {
    name,
    metaDescription,
    keywords,
    slug,
    subcategory,
    heroImage,
    address,
    about,
    sundayOpeningHours,
    mondayOpeningHours,
    tuesdayOpeningHours,
    wednesdayOpeningHours,
    thursdayOpeningHours,
    fridayOpeningHours,
    saturdayOpeningHours,
    phoneNumber,
    externalLink,
    video,
    carouselImages,
    onlyInFifth,
    tips,
    category,
    description,
    comment,
    commentsAuthor,
    nearbyRecommendations,
    transitStations,
  } = data.contentfulRetailer

  const featuredItineraries: IYouMayAlsoLike[] = data.allContentfulItinerary.edges.map(
    itinerary => {
      const node = itinerary.node
      return {
        slug: node.slug,
        heroImage: node.image,
        name: node.title,
        type: TypeToDisplay.ITINERARY,
      }
    }
  )
  const mayAlsoLikeRetails = useRef(
    sampleSize(
      data.allContentfulRetailer.edges
        .map(edge => edge.node)
        .filter(
          item =>
            category[0].name === item.category[0].name && item.display !== false
        ),
      3
    )
  )
  const TransformedRetailers: IYouMayAlsoLike[] = mayAlsoLikeRetails.current.map(
    retailer => {
      return {
        slug: retailer.slug,
        name: retailer.name,
        category: retailer.category,
        subcategory: retailer.subcategory,
        heroImage: retailer.heroImage,
        type: TypeToDisplay.RETAILER,
        addressMiniatureIndication: retailer.addressMiniatureIndication,
      }
    }
  )
  const YouMayLikeArray: IYouMayAlsoLike[] = featuredItineraries.concat(
    TransformedRetailers
  )
  const [highlightedElement, setHighlightedElement] = useState<
    SimpleRetailer | TransitStation | ParkingLot
  >()

  const highlightRefs: any = {}

  useEffect(() => {
    if (
      isRetailer(highlightedElement) &&
      highlightRefs[`${highlightedElement.address}-${highlightedElement.name}`]
    ) {
      scrollToRef(
        highlightRefs[
          `${highlightedElement.address}-${highlightedElement.name}`
        ]
      )
    }
  }, [highlightedElement])

  const nearbyRetailerAddresses: MapRetailer[] | undefined = useMemo(
    () =>
      nearbyRecommendations
        ? ([
            {
              ...data.contentfulRetailer,
              // Tinily hacky way to display the custom category
              subcategory: [{ name: "You Are Here" }],
            },
          ] as ApiRetailer[])
            .concat(nearbyRecommendations)
            .map(elem => ({
              ...elem,
              category: elem.subcategory?.[0].name || elem.category?.[0].name,
            }))
        : undefined,
    [nearbyRecommendations, data.contentfulRetailer]
  )

  // todo: have coherent typing
  if (nearbyRetailerAddresses)
    nearbyRetailerAddresses.map(
      item => (highlightRefs[`${item.address}-${item.name}`] = useRef())
    )

  const subwayStations = useMemo(
    () =>
      transitStations?.filter(station => station.category === "Subway Station"),
    [transitStations]
  )

  const parkings = useMemo(
    () => data.allContentfulParkingLot.edges.map(edge => edge.node),
    [data.allContentfulParkingLot]
  )

  const onHighlight = useCallback(
    (markerId: string) => {
      const retailer = nearbyRecommendations?.find(
        element => element.address === markerId
      )
      if (retailer) {
        return setHighlightedElement(retailer)
      }
      const station = transitStations
        .filter(station => station.category === "Subway Station")
        .find(station => station.id === markerId)
      if (station) {
        return setHighlightedElement(station)
      }
      const parking = parkings.find(parking => parking.id === markerId)
      if (parking) {
        return setHighlightedElement(parking)
      }
    },
    [nearbyRecommendations, transitStations, parkings]
  )

  const settings = {
    centerMode: true,
    infinite: true,
    speed: 2000,
    variableWidth: true,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          autoplay: false,
          autoplaySpeed: 0,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          adaptiveHeight: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          autoplay: false,
          autoplaySpeed: 0,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          adaptiveHeight: true,
          arrows: false,
        },
      },
    ],
  }

  const hasOpeningHours =
    mondayOpeningHours &&
    tuesdayOpeningHours &&
    wednesdayOpeningHours &&
    thursdayOpeningHours &&
    fridayOpeningHours &&
    saturdayOpeningHours &&
    sundayOpeningHours

  const openingHours = hasOpeningHours
    ? open({
        sundayOpeningHours,
        mondayOpeningHours,
        tuesdayOpeningHours,
        wednesdayOpeningHours,
        thursdayOpeningHours,
        fridayOpeningHours,
        saturdayOpeningHours,
      })
    : null

  return (
    <Layout>
      <SEO
        title={name}
        description={metaDescription || description?.description || undefined}
        keywords={keywords || ""}
      />
      <RichSnippet
        category={category}
        heroImage={heroImage}
        slug={slug}
        name={name}
        address={address}
        externalLink={externalLink}
        phoneNumber={phoneNumber}
        subcategory={subcategory}
        openingHours={[
          mondayOpeningHours,
          tuesdayOpeningHours,
          wednesdayOpeningHours,
          thursdayOpeningHours,
          fridayOpeningHours,
          saturdayOpeningHours,
          sundayOpeningHours,
        ]}
      />
      <RetailerHeader
        openingHours={openingHours}
        name={name}
        onlyInFifth={onlyInFifth}
        category={category}
        description={description?.description}
        hasOpeningHours={hasOpeningHours}
        heroImage={heroImage}
      />
      <Style.BackButton.Container onlyInFifth={onlyInFifth}>
        <Style.BackButton.Link
          color={COLORS.HOLLY_GREEN}
          to={"/" + category[0].slug}
          reverse
        >
          Back to {category[0].name}
        </Style.BackButton.Link>
      </Style.BackButton.Container>

      <Style.Body.Background>
        <Style.Body.Hours>
          <Style.Body.WhenTop>HOURS</Style.Body.WhenTop>
          {hasOpeningHours &&
            HoursSection({
              sundayOpeningHours,
              mondayOpeningHours,
              tuesdayOpeningHours,
              wednesdayOpeningHours,
              thursdayOpeningHours,
              fridayOpeningHours,
              saturdayOpeningHours,
            })}

          {address && (
            <>
              <Style.Body.When>ADDRESS</Style.Body.When>
              <Style.Body.Date>{address}</Style.Body.Date>
              <Style.Body.ReadMore
                $textColor={COLORS.HOLLY_GREEN}
                to={
                  "https://www.google.com/maps/search/?api=1&query=" +
                  encodeURIComponent(address)
                }
              >
                Get Directions
              </Style.Body.ReadMore>
            </>
          )}
          {transitStations && (
            <>
              <Style.Body.When>GETTING THERE</Style.Body.When>
              {transitStations.map(station => (
                <Style.Body.StationContainer key={station.id}>
                  <Style.Body.StationName>
                    {station.name}
                  </Style.Body.StationName>
                  {station.transitLines?.map(line => (
                    <Style.Body.StationLogo
                      key={line.name}
                      src={line.logo.file.url}
                      alt={line.name}
                    />
                  ))}
                </Style.Body.StationContainer>
              ))}
            </>
          )}
          <Style.Body.When>CONTACT</Style.Body.When>
          <Style.Body.ContactContainer>
            {externalLink && (
              <Style.Body.WebSite as={AutoLink} to={externalLink}>
                Website
              </Style.Body.WebSite>
            )}
            {phoneNumber && <Style.Body.Phone>{phoneNumber}</Style.Body.Phone>}
          </Style.Body.ContactContainer>
          <Style.Body.WhenBottom />
        </Style.Body.Hours>

        <Style.Body.ContentBackground>
          {about && about.about && (
            <Style.Body.Container>
              <Style.Body.Title $textColor={COLORS.HOLLY_GREEN}>
                About {name}
              </Style.Body.Title>

              <Style.Body.LongContent
                $textColor={COLORS.CHARCOAL}
                dangerouslySetInnerHTML={{
                  __html: about.childMarkdownRemark.html,
                }}
              />
            </Style.Body.Container>
          )}
          {tips && tips.tips && (
            <Style.Body.TipsContainer onlyInFifth={onlyInFifth}>
              <Style.Body.TipsTitle $textColor={COLORS.FANTASY_WHITE}>
                Tips from fifth
              </Style.Body.TipsTitle>
              <>
                <Style.Body.TipsLongContent $textColor={COLORS.HOLLY_GREEN}>
                  {tips.tips}
                </Style.Body.TipsLongContent>
              </>
            </Style.Body.TipsContainer>
          )}
        </Style.Body.ContentBackground>
      </Style.Body.Background>

      {nearbyRecommendations && (
        <Style.NearbyRecommandations.Title
          $textColor={COLORS.BOTTLE_GREEN}
          onlyInFifth={onlyInFifth}
        >
          Nearby Recommendations
        </Style.NearbyRecommandations.Title>
      )}
      {nearbyRetailerAddresses && nearbyRecommendations && (
        <Style.Map.root>
          <Style.Map.MapContainer>
            <MapBox
              elements={nearbyRetailerAddresses}
              highlightedId={
                isRetailer(highlightedElement)
                  ? highlightedElement.address
                  : highlightedElement?.id
              }
              onHighlight={onHighlight}
              centerAddress={address}
              stations={subwayStations}
              parkings={parkings}
            />
          </Style.Map.MapContainer>

          <Media greaterThanOrEqual="md">
            <Style.Map.RetailersContainer>
              {nearbyRecommendations
                .sort(sortRetailers)
                .map((retailer, index) => {
                  let timer: number
                  return (
                    <RetailerMapCard
                      key={retailer.slug}
                      retailer={retailer}
                      isHighlighted={
                        isRetailer(highlightedElement) &&
                        highlightedElement.address === retailer.address
                      }
                      isTop={index === 0}
                      reference={
                        highlightRefs[`${retailer.address}-${retailer.name}`]
                      }
                      onMouseLeave={() => clearTimeout(timer)}
                      onMouseEnter={() =>
                        (timer = setTimeout(() => {
                          setHighlightedElement(retailer)
                        }, 1500))
                      }
                    />
                  )
                })}
            </Style.Map.RetailersContainer>
          </Media>
          <Media lessThan="md">
            {isRetailer(highlightedElement) &&
              highlightedElement.address != "" &&
              (nearbyRecommendations.filter(
                e => e.address === highlightedElement.address
              ).length === 1 ? (
                <Style.Map.SingleRetailerContainer
                  key={highlightedElement.id}
                  ref={
                    highlightRefs[
                      `${highlightedElement.address}-${highlightedElement.name}`
                    ]
                  }
                >
                  <RetailerMapCard retailer={highlightedElement} />
                </Style.Map.SingleRetailerContainer>
              ) : (
                <Style.Map.MultipleRetailerContainer.root
                  ref={
                    highlightRefs[
                      `${highlightedElement.address}-${highlightedElement.name}`
                    ]
                  }
                >
                  <Style.Map.MultipleRetailerContainer.retailersCount>
                    {
                      nearbyRecommendations.filter(
                        e => e.address === highlightedElement.address
                      ).length
                    }{" "}
                    PLACES to visit here
                  </Style.Map.MultipleRetailerContainer.retailersCount>
                  <Style.Map.MultipleRetailerContainer.ticker>
                    <Ticker lightTheme manual>
                      {nearbyRecommendations
                        .filter(
                          retailer =>
                            retailer.address === highlightedElement.address
                        )
                        .map(retailer => (
                          <Style.Map.SingleRetailerContainer
                            key={retailer.name}
                            ref={
                              highlightRefs[
                                `${retailer.address}-${retailer.name}`
                              ]
                            }
                          >
                            <RetailerMapCard retailer={retailer} />
                          </Style.Map.SingleRetailerContainer>
                        ))}
                    </Ticker>
                  </Style.Map.MultipleRetailerContainer.ticker>
                </Style.Map.MultipleRetailerContainer.root>
              ))}
            {!isRetailer(highlightedElement) && highlightedElement && (
              <Style.Map.SingleRetailerContainer key={highlightedElement.id}>
                {isStation(highlightedElement) ? (
                  <StationMapCard station={highlightedElement} />
                ) : (
                  <ParkingMapCard parking={highlightedElement} />
                )}
              </Style.Map.SingleRetailerContainer>
            )}
          </Media>
        </Style.Map.root>
      )}
      {comment &&
        comment.childMarkdownRemark &&
        comment.childMarkdownRemark.html && (
          <Style.Body.CommentContainer onlyInFifth={onlyInFifth}>
            <Style.Body.CommentLongContent
              $textColor={COLORS.HOLLY_GREEN}
              dangerouslySetInnerHTML={{
                __html: comment.childMarkdownRemark.html,
              }}
            ></Style.Body.CommentLongContent>
            <Style.Body.CommentsAuthorContent $textColor={COLORS.HOLLY_GREEN}>
              {"- " + commentsAuthor}
            </Style.Body.CommentsAuthorContent>
          </Style.Body.CommentContainer>
        )}
      <br />

      {carouselImages && carouselImages.length && (
        <Style.CarouselContainer>
          <Slider {...settings}>
            {carouselImages.map(image => (
              <Style.CarouselImage
                key={image.id}
                fluid={image.fluid}
                alt={image.title}
              />
            ))}
          </Slider>
        </Style.CarouselContainer>
      )}
      {video && video.file && video.file.url && (
        <Style.VideoContainer>
          <Style.VideoWrapper>
            <video controls>
              <source src={video.file.url} type={video.file.contentType} />
              Your browser does not support video.
            </video>
          </Style.VideoWrapper>
        </Style.VideoContainer>
      )}
      <Style.AlsoLikeTicker.Title onlyInFifth={onlyInFifth}>
        YOU MAY ALSO LIKE
      </Style.AlsoLikeTicker.Title>
      <Style.AlsoLikeTicker.Container onlyInFifth={onlyInFifth}>
        <Ticker>
          {YouMayLikeArray.map((element: IYouMayAlsoLike) => {
            const address = element.addressMiniatureIndication
              ? element.addressMiniatureIndication.split(" ")
              : []
            const category =
              element.type === TypeToDisplay.RETAILER
                ? element.subcategory?.[0].name || element.category?.[0].name
                : `Itinerary`
            const slug =
              element.type === TypeToDisplay.RETAILER
                ? element.slug
                : `itineraries/${element.slug}`

            return (
              <RetailerTickerCard
                retailer={element}
                address={address}
                category={category}
                slug={slug}
                key={slug}
              />
            )
          })}
        </Ticker>
      </Style.AlsoLikeTicker.Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    allContentfulItinerary(filter: { title: { ne: "Dummy content" } }) {
      edges {
        node {
          title
          image {
            title
            fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyContentfulFluid
            }
          }
          slug
        }
      }
    }
    contentfulRetailer(slug: { eq: $slug }) {
      id
      name
      address
      metaDescription
      keywords
      additionalAddressInfo
      slug
      subcategory {
        name
      }
      heroImage {
        title
        file {
          url
        }
        fluid(quality: 90, maxWidth: 2000) {
          ...GatsbyContentfulFluid
        }
      }
      about {
        about
        childMarkdownRemark {
          html
        }
      }
      centralImage {
        title
        fluid(quality: 90, maxWidth: 2000) {
          ...GatsbyContentfulFluid
        }
      }
      sundayOpeningHours
      mondayOpeningHours
      tuesdayOpeningHours
      wednesdayOpeningHours
      thursdayOpeningHours
      fridayOpeningHours
      saturdayOpeningHours
      phoneNumber
      externalLink
      instagramLink
      carouselImages {
        id
        title
        fluid(quality: 90, maxWidth: 1000) {
          ...GatsbyContentfulFluid
        }
        fixed(width: 200, height: 200) {
          ...GatsbyContentfulFixed
        }
      }
      video {
        file {
          url
          contentType
        }
      }
      onlyInFifth
      category {
        id
        name
        slug
      }
      description {
        description
      }
      tips {
        tips
      }
      onlyInFifth
      comment {
        childMarkdownRemark {
          html
        }
      }
      commentsAuthor
      exactLocation {
        lat
        lon
      }
      nearbyRecommendations {
        slug
        address
        heroImage {
          title
          fluid(quality: 90, maxWidth: 1000) {
            ...GatsbyContentfulFluid
          }
        }
        name
        category {
          name
        }
        subcategory {
          name
        }
        tips {
          tips
        }
        exactLocation {
          lat
          lon
        }
      }
      transitStations {
        id
        name
        transitLines {
          id
          name
          logo {
            file {
              url
            }
          }
        }
        category
        location {
          lat
          lon
        }
      }
    }
    allContentfulRetailer {
      edges {
        node {
          heroImage {
            title
            fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyContentfulFluid
            }
          }
          display
          name
          address
          addressMiniatureIndication
          onlyInFifth
          favoriteLocation
          slug
          category {
            name
          }
          subcategory {
            name
          }
          exactLocation {
            lat
            lon
          }
        }
      }
    }
    allContentfulParkingLot {
      edges {
        node {
          id
          name
          image {
            title
            fluid(quality: 90) {
              ...GatsbyContentfulFluid
            }
          }
          addressToShow
          location {
            lat
            lon
          }
        }
      }
    }
  }
`

export default Retailer
