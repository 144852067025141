import { MOBILE_MAX_WIDTH } from "../styles"

export const scrollToRef = (ref: any) =>
  ref.current.scrollIntoView({
    behavior: "smooth",
    block:
      typeof window !== "undefined" && window.innerWidth <= MOBILE_MAX_WIDTH
        ? "end"
        : "center",
  })
