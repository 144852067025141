import { FluidObject } from "gatsby-image"
import React, { FunctionComponent } from "react"
import { Category } from "../../services/retailersService"
import Style from "./retailer.style"

interface Props {
  name: string
  heroImage: {
    fluid: FluidObject
    title: string
  }
  category: Category[]
  onlyInFifth: boolean
  description: string | undefined
  hasOpeningHours: string
  openingHours: string | null
}

const RetailerHeader: FunctionComponent<Props> = ({
  heroImage,
  name,
  category,
  onlyInFifth,
  description,
  hasOpeningHours,
  openingHours,
}) => (
  <Style.HeroContainer>
    <Style.HeroImage
      fluid={heroImage && heroImage.fluid}
      alt={heroImage && heroImage.title}
    />
    <Style.TitleContainer>
      {category &&
        !onlyInFifth &&
        category.map(cat => (
          <Style.Category key={cat.id}>{cat.name}</Style.Category>
        ))}
      {onlyInFifth && <Style.OnlyInFifth>Only On Fifth</Style.OnlyInFifth>}
      <Style.Title $textColor="white">{name}</Style.Title>
      {description && (
        <Style.Description $textColor="white">{description}</Style.Description>
      )}
      {hasOpeningHours && (
        <Style.Hours $textColor="white">
          <Style.ClockIcon />
          {openingHours}
        </Style.Hours>
      )}
    </Style.TitleContainer>
  </Style.HeroContainer>
)

export default RetailerHeader
