import React, { FunctionComponent, SyntheticEvent } from "react"
import { AutoLink } from "../link"
import Style from "./button.style"

interface Props {
  onClick?: () => void
  to?: string
  icon?: "leftPlus" | "rightPlus" | "ticket" | "download"
  mode?: "button" | "link"
  withBackground?: boolean
  full:Boolean
}
const Button: FunctionComponent<Props> = ({
  children,
  icon,
  mode = "button",
  withBackground,
  full,
  ...props
}) => (
  <Style.Button
    as={mode === "button" ? "button" : AutoLink}
    onMouseDown={(e: SyntheticEvent) => {
      //Prevent focus on click
      e.nativeEvent.preventDefault()
    }}
    withBackground={withBackground}
    {...props}
    full={full | null}
  >
    {icon === "ticket" && <Style.TicketIcon />}
    <span style={{marginRight:'10px'}}>{children}</span>
    {icon === "download" && <Style.DownloadIcon />}
    {icon === "leftPlus" && <Style.LeftPlus />}
    {icon === "rightPlus" && <Style.RightPlus />}
  </Style.Button>
)

export default Button
