import { FluidObject } from "gatsby-image"
import { ParkingLot, TransitStation } from "../components/explore/map.client"

export interface ApiRetailer extends SimpleRetailer {
  addressMiniatureIndication: string
  phoneNumber: string
  lastColumnCategoryPageInformation: string
  display: boolean
  onlyInFifth: boolean
  favoriteLocation: boolean
}
export type SimpleRetailer = {
  id: string
  name: string
  address: string
  tips: { tips: string }
  heroImage: { fluid: FluidObject; title: string }
  slug: string
  category: Category[]
  subcategory: { name: string }[]
  exactLocation: { lat: number; lon: number } | null
}

export const isRetailer = (
  element: SimpleRetailer | TransitStation | ParkingLot | undefined
): element is SimpleRetailer => !!(element as SimpleRetailer)?.slug

export const isStation = (
  element: SimpleRetailer | TransitStation | ParkingLot | undefined
): element is TransitStation => !!(element as TransitStation)?.category

export interface HoursProps {
  sundayOpeningHours: string
  mondayOpeningHours: string
  tuesdayOpeningHours: string
  wednesdayOpeningHours: string
  thursdayOpeningHours: string
  fridayOpeningHours: string
  saturdayOpeningHours: string
}
export const sortRetailers = (
  firstRetailer: ApiRetailer,
  secondRetailer: ApiRetailer
): number => {
  if (firstRetailer.name > secondRetailer.name) return 1
  if (firstRetailer.name < secondRetailer.name) return -1
  return 0
}

export interface Category {
  id: string
  name: string
  slug: string
}

export enum TypeToDisplay {
  ITINERARY = "ITINERARY",
  RETAILER = "RETAILER",
}

export interface IYouMayAlsoLike {
  slug: string
  heroImage: {
    title: string
    fluid: FluidObject
  }
  name: string
  category?: {
    name: string
  }[]
  subcategory?: {
    name: string
  }[]
  addressMiniatureIndication?: string
  type: TypeToDisplay
}

export interface RetailerTickerCardProps {
  retailer: IYouMayAlsoLike
  slug: string
  category: string | undefined
  address: string[]
}

export const getRetailersByGroupId = (
  retailersGroupedByName: Record<string, ApiRetailer[]>,
  groupId: string
): ApiRetailer[] =>
  groupId
    .split("")
    .reduce(
      (result, char) =>
        retailersGroupedByName.hasOwnProperty(char)
          ? result.concat(retailersGroupedByName[char])
          : result,
      [] as ApiRetailer[]
    )

export const groupRetailersByName = (
  retailers: ApiRetailer[]
): { [key: string]: ApiRetailer[] } =>
  retailers.reduce((result, retailer) => {
    if (retailer.name.length === 0) {
      return result
    }
    let retailerFirstLetter = retailer.name[0].toLowerCase()
    if (!retailerFirstLetter.match(/[a-z]/)) {
      retailerFirstLetter = "#"
    }
    if (result.hasOwnProperty(retailerFirstLetter)) {
      result[retailerFirstLetter].push(retailer)
    } else {
      result[retailerFirstLetter] = [retailer]
    }
    return result
  }, {} as { [key: string]: ApiRetailer[] })
