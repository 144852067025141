import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import {
  COLORS,
  FONTS,
  IPAD_MAX_WIDTH,
  MAX_WIDTH_LAYOUT,
  MOBILE_MAX_WIDTH,
  Z_INDEXES,
} from "../../styles"
import RightArrow from "../../images/right-arrow.svg"
import ArrowIcon from "../../images/arrow.svg"
import { AutoLink } from "../../components/link"
import ClockIcon from "../../images/clock.svg"
import ArrowRightGreen from "../../images/arrow-right.svg"
import { Tag } from "../../components/common/tag"
import {
  H2,
  H4,
  Q2,
  S1,
  S2,
  T1,
  T4,
  HotelTitle,
} from "../../components/common/text"
import { CTA } from "../../components/common/cta"

const IMAGES_WIDTH = 60
const DESKTOP_MARGIN = "5rem"
const MOBILE_MARGIN = "5rem"

interface MapCardContainerProps {
  highlight?: boolean
  isTop?: boolean
}

interface ThemeColorContainer {
  onlyInFifth?: boolean
}

export default {
  HeroContainer: styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 45px;
    background-color: ${COLORS.HOLLY_GREEN};
    height: 475px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      padding-top: 0;
      flex-direction: column;
      height: unset;
      max-height: 785px;
    }
  `,

  HeroImage: styled(Img)`
    object-fit: contain;
    width: 638px;
    height: 430px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 100%;
      height: 255px;
    }
  `,
  TitleContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 105px 0px 105px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 100%;
      padding: 30px;
    }
  `,
  Title: styled(HotelTitle)`
    margin-top: 25px;
    margin-bottom: 10px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      margin-bottom: 5px;
    }
  `,
  AboutContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${DESKTOP_MARGIN};
    margin-bottom: calc(${DESKTOP_MARGIN} / 2);
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      margin-top: ${MOBILE_MARGIN};
      margin-bottom: calc(${MOBILE_MARGIN} / 2);
    }
  `,
  CentralContainer: styled.div`
    position: relative;
    height: 750px;
    margin-top: ${DESKTOP_MARGIN};
    margin-bottom: calc(${DESKTOP_MARGIN} / 2);

    @media screen and (max-width: ${IPAD_MAX_WIDTH}px) {
      height: 35rem;
    }

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      height: 25rem;
      margin-top: ${MOBILE_MARGIN};
      margin-bottom: calc(${MOBILE_MARGIN} / 2);
    }
  `,
  CentralImageWrapper: styled.div`
    position: absolute;
    top: 0;
    right: 0;
    z-index: ${Z_INDEXES.RETAILER_CENTRAL_IMAGE};
    width: ${IMAGES_WIDTH}vw;
    height: 85%;
    overflow-y: hidden;

    @media screen and (max-width: ${IPAD_MAX_WIDTH}px) {
      height: 75%;
    }

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      height: 85%;
    }
  `,
  CentralImage: styled(Img)`
    width: 100%;
    height: 100%;
  `,
  ContactContainer: styled.div`
    margin: ${DESKTOP_MARGIN} 3rem calc(${DESKTOP_MARGIN} / 2) 3rem;
    display: flex;
    justify-content: space-around;

    @media screen and (max-width: ${IPAD_MAX_WIDTH}px) {
      margin: ${MOBILE_MARGIN} 1rem calc(${MOBILE_MARGIN} / 2) 1rem;
    }

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      flex-direction: column;
      margin: ${MOBILE_MARGIN} 1rem calc(${MOBILE_MARGIN} / 2) 1rem;
    }
  `,
  ContactCol: styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 33%;

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      flex-basis: 100%;

      &:not(:first-child) {
        margin-top: ${MOBILE_MARGIN};
      }
    }
  `,

  OpeningHourContainer: styled.table`
    width: auto;
    td {
      border: none;
      padding: 0.25rem 2rem 0.25rem 2rem;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        padding-right: 0;
        padding-left: 1rem;
      }
    }
  `,
  Link: styled.a`
    color: ${COLORS.BOTTLE_GREEN};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  `,
  ArrowIcon: styled(ArrowIcon)`
    width: 0.4rem;
    height: 0.6rem;
    stroke-width: 0.7;
    margin-left: 0.4em;
    color: ${COLORS.BOTTLE_GREEN};
  `,
  InstagramContainer: styled.div`
    color: ${COLORS.WHITE};
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin-top: ${DESKTOP_MARGIN};
    margin-bottom: calc(${DESKTOP_MARGIN} / 2);

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      flex-direction: column-reverse;
      margin-top: ${MOBILE_MARGIN};
      margin-bottom: calc(${MOBILE_MARGIN} / 2);
    }
  `,
  VideoContainer: styled.div`
    margin-top: ${DESKTOP_MARGIN};
    margin-bottom: calc(${DESKTOP_MARGIN} / 2);
    display: flex;

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      flex-direction: column-reverse;
      margin-top: ${MOBILE_MARGIN};
      margin-bottom: calc(${MOBILE_MARGIN} / 2);
    }
  `,
  VideoWrapper: styled.div`
    margin-left: calc(100vw - ${IMAGES_WIDTH}vw);
    width: ${IMAGES_WIDTH}vw;
    video {
      width: 100%;
      height: auto;
    }

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      margin-left: 0;
      width: 100%;
      padding: 0 1rem;
    }
  `,
  CarouselContainer: styled.div`
    padding-bottom: 55px;
    padding-top: 30px;
    overflow-x: hidden;
    .slick-prev,
    .slick-next {
      position: absolute;
      top: 50%;
      z-index: 1;
    }
    .slick-prev {
      left: 0px;
    }
    .slick-next {
      right: 0px;
    }
    .slick-prev:before,
    .slick-next:before {
      display: none;
    }
    .slick-dots {
      position: static;
      margin-left: -13px;
    }
    .slick-dots li {
      margin-right: -13px;
    }

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      padding-bottom: 28px;
      padding-top: 0px;
      .slick-track {
        display: flex;
      }
      .slick-track .slick-slide {
        display: flex;
        height: auto;
        align-items: center;
        justify-content: center;
      }
    }
  `,
  CarouselImage: styled(Img)`
    margin: 0px 6px;
    width: 580px !important;
    height: 580px;
    object-fit: contain;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      margin: 0 60px;
      width: 318px !important;
      height: 318px;
    }
  `,
  CarouselNextArrow: styled(ArrowIcon)`
    && {
      color: ${COLORS.WHITE};
      width: 2rem;
      height: 2rem;
      stroke-width: 0.25;
      margin: 8px;
      &:hover {
        color: ${COLORS.BLUE_CHALK_VIOLET};
      }
    }
  `,
  CarouselPrevArrow: styled(ArrowIcon)`
    && {
      transform: rotate(180deg);
      color: ${COLORS.WHITE};
      width: 2rem;
      height: 2rem;
      stroke-width: 0.25;
      margin: 8px;

      &:hover {
        color: ${COLORS.BLUE_CHALK_VIOLET};
      }
    }
  `,
  OnlyInFifth: styled(Tag)`
    background-color: ${COLORS.BLUE_CHALK_VIOLET};
    color: ${COLORS.BOTTLE_GREEN};
  `,
  Body: {
    Background: styled.div`
      max-width: ${MAX_WIDTH_LAYOUT}px;
      margin: 0 auto;
      background-color: ${COLORS.WHITE};
      padding-top: 65px;
      padding-bottom: 65px;
      padding-left: 10%;
      padding-right: 10%;
      display: flex;
      flex-direction: row;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        flex-direction: column;
        padding-left: 28px;
        padding-right: 28px;
        padding-top: 10px;
        padding-bottom: 35px;
      }
    `,
    ContentBackground: styled.div`
      width: 60%;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        width: 100%;
        margin: auto;
      }
    `,
    StationContainer: styled.div`
      margin-bottom: 10px;
    `,
    StationName: styled(T1)`
      margin-bottom: 3px;
    `,
    StationLogo: styled.img`
      width: 15px;
      height: 15px;
      margin-right: 4px;
    `,

    Container: styled.div`
      flex: 1;
      padding-bottom: 50px;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        width: 100%;
        margin-right: 0;
      }
    `,
    TipsContainer: styled.div<ThemeColorContainer>`
      padding: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: ${props =>
        props.onlyInFifth ? COLORS.CHALK_VIOLET : COLORS.SEASHELL};
      text-align: center;
      align-items: center;
      min-height: 222px;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        min-height: 270px;
        padding: 25px;
      }
    `,
    Date: styled(T1)`
      margin-bottom: 5px;
    `,
    Phone: styled(T1)`
      margin-top: 10px;
    `,
    WebSite: styled(T1)`
      text-decoration: underline;
    `,
    ContactContainer: styled.div`
      margin-bottom: 15px;
    `,
    Row: styled.div`
      display: flex;
      justify-content: space-between;
    `,
    Day: styled.div`
      margin-bottom: 15px;
      margin-top: 10px;
    `,
    Hours: styled(T1)`
      width: 30%;
      margin-right: 10%;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        width: 100%;
        padding-bottom: 0px;
        margin: auto;
        align: center;
      }
    `,
    When: styled(S1)`
      padding-top: 20px;
      border-top: 1px solid ${COLORS.HOLLY_GREEN};
      margin-bottom: 10px;
      margin-top: 10px;
    `,
    WhenTop: styled(S1)`
      padding-top: 20px;
      border-top: 1px solid ${COLORS.HOLLY_GREEN};
      margin-bottom: 10px;
      margin-top: 10px;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        border-top: none;
        margin-top: 0px;
      }
    `,
    WhenBottom: styled(S1)`
      padding-top: 20px;
      border-top: 1px solid ${COLORS.HOLLY_GREEN};
      margin-bottom: 10px;
      margin-top: 10px;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        margin-bottom: 0px;
        padding-top: 10px;
      }
    `,
    Buy: styled.div`
      margin-top: 30px;
    `,
    Title: styled(S1)`
      margin-bottom: 10px;
      margin-top: 10px;
    `,
    TipsTitle: styled(S1)`
      background-color: ${COLORS.HOLLY_GREEN};
      width: fit-content;
      padding: 6px 8px 6px 9px;
    `,
    LongContent: styled(T1)`
      margin-top: 15px;
      a {
        color: ${COLORS.BOTTLE_GREEN};
      }
    `,
    TipsLongContent: styled(Q2)`
      padding-top: 20px;
    `,
    ReadMore: styled(CTA)`
      margin-bottom: 15px;
    `,
    Arrow: styled(RightArrow)`
      margin-left: 0px;
      fill: ${COLORS.BOTTLE_GREEN};
    `,
    Directions: styled.span`
      margin-right: 0px;
      &:hover {
        margin-right: 3px;
      }
    `,
    CommentContainer: styled.div<ThemeColorContainer>`
      display: flex;
      flex-direction: column;
      background-color: ${props =>
        props.onlyInFifth ? COLORS.CHALK_VIOLET : COLORS.SEASHELL};
      align-items: center;
      justify-content: center;
      text-align: center;
      min-height: 205px;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        width: 100%;
        min-height: 290px;
      }
    `,
    CommentLongContent: styled(Q2)`
      max-width: 60%;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        width: 80%;
      }
    `,
    CommentsAuthorContent: styled(T4)`
      max-width: 80%;
      margin-top: 25px;
    `,
  },
  BackButton: {
    Container: styled.div<ThemeColorContainer>`
      height: 60px;
      background-color: ${props =>
        props.onlyInFifth ? COLORS.CHALK_VIOLET : COLORS.SEASHELL};
      padding-left: 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        height: 40px;
      }
    `,
    Link: styled(CTA)``,
  },

  NearbyRecommandations: {
    Title: styled(H4)<ThemeColorContainer>`
      display: flex;
      height: 60px;
      background-color: ${props =>
        props.onlyInFifth ? COLORS.BLUE_CHALK_VIOLET : COLORS.BEIGE};
      justify-content: center;
      align-items: center;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        margin-bottom: 0px;
        font-size: 28px;
      }
    `,
  },
  Category: styled(Tag)`
    color: ${COLORS.BOTTLE_GREEN};
    background-color: ${COLORS.BEIGE};
  `,
  Description: styled(T1)``,

  Hours: styled(T4)`
    margin-top: 30px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      margin-top: 25px;
    }
  `,
  ClockIcon: styled(ClockIcon)`
    height: 22px;
    width: 22px;
    margin-right: 10px;
    margin-bottom: -0.35rem;
    fill: ${COLORS.WHITE};
  `,

  AlsoLikeTicker: {
    Link: styled(AutoLink)``,
    Container: styled.div<{ onlyInFifth: boolean }>`
      height: 530px;
      background-color: ${props =>
        props.onlyInFifth ? COLORS.CHALK_VIOLET : COLORS.SEASHELL};
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        height: 550px;
      }
    `,
    Title: styled(S2)<{ onlyInFifth: boolean }>`
      width: 100%;
      display: flex;
      justify-content: center;
      height: 120px;
      align-items: center;
      background-color: ${props =>
        props.onlyInFifth ? COLORS.CHALK_VIOLET : COLORS.SEASHELL};
      text-align: center;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        height: 90px;
      }
    `,
    Element: styled.div`
      display: flex;
      flex-direction: column;
      width: 690px;
      margin-right: 2%;
      height: fit-content;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        width: 346px;
      }
    `,
    ImageHolder: styled.div`
      display: flex;
      flex-direction: row;
    `,
    Name: styled(H4)`
      margin-top: 5px;
      margin-bottom: 20px;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        width: 80%;
      }
    `,

    ArrowRightGreenIcon: styled(ArrowRightGreen)`
      height: 0.5rem;
      fill: ${COLORS.BOTTLE_GREEN};
    `,
    Address: styled.div`
      display: flex;
      flex-direction: column;
      align-items: left;
      margin-left: 13px;
    `,
    AddressElement: styled(H2)`
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      text-transform: uppercase;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        margin-bottom: 10px;
      }
    `,
    Category: styled(S1)`
      margin-top: 15px;
    `,
  },

  Map: {
    root: styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 50px;
      padding-top: 50px;

      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        flex-direction: column;
        padding-bottom: 0px;
        padding-top: 0px;
      }
    `,

    RetailersContainer: styled.div`
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      ::-webkit-scrollbar {
        display: none;
      }
      height: 522px;
      width: 366px;
      margin-left: 32.5px;
    `,
    SingleRetailerContainer: styled.div`
      display: flex;
      height: 92px;
      width: 100vw;
    `,
    MultipleRetailerContainer: {
      root: styled.div`
        display: flex;
        flex-direction: column;
        height: 152px;
        width: 100%;
      `,
      ticker: styled.div`
        height: calc(152px - 30px);
        width: 100vw;
        ol {
          bottom: 10px;
          padding-top: 10px;
          border-top: 1px solid ${COLORS.BOTTLE_GREEN};
        }
      `,
      retailersCount: styled.div`
        width: 100%;
        height: 30px;
        background-color: ${COLORS.BOTTLE_GREEN};
        color: ${COLORS.WHITE};
        font-family: ${FONTS.SOHNE_HALBFETT};
        font-size: 12px;
        line-height: 1.25;
        letter-spacing: 1.44px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
      `,
    },
    MapContainer: styled.div`
      width: 780px;
      height: 522px;
      @media screen and (max-width: 1024px) {
        width: 60%;
      }
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        width: 100%;
      }
    `,
  },
}
