import React, { FunctionComponent } from "react"
import { Media } from "../../Media"
import { SimpleRetailer } from "../../services/retailersService"
import { COLORS } from "../../styles"
import Style from "./explore.style"
import { ParkingLot, TransitStation } from "./map.client"

interface RetailerCardProps {
  retailer: SimpleRetailer
  isHighlighted?: boolean
  isTop?: boolean
  reference?: any
  onMouseLeave?: any
  onMouseEnter?: any
}

export const RetailerMapCard: FunctionComponent<RetailerCardProps> = ({
  retailer,
  isHighlighted,
  isTop,
  reference,
  onMouseEnter,
  onMouseLeave,
}) => (
  <Style.Map.Card.Container
    ref={reference}
    highlight={isHighlighted}
    isTop={isTop}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <Style.Map.Card.Card to={"/" + retailer.slug}>
      <Style.Map.Card.Image
        fluid={retailer.heroImage.fluid}
        alt={retailer.heroImage.title}
      />
      <Style.Map.Card.Content>
        <Style.Map.Card.Category>
          {retailer.subcategory?.[0].name || retailer.category?.[0].name}
        </Style.Map.Card.Category>
        <Style.Map.Card.Name>{retailer.name}</Style.Map.Card.Name>
        <Style.Map.Card.Address $textColor={COLORS.CHARCOAL}>
          {retailer.address}
        </Style.Map.Card.Address>
      </Style.Map.Card.Content>
    </Style.Map.Card.Card>
    <Media greaterThanOrEqual="md">
      {isHighlighted && retailer.tips && (
        <Style.MoreInfoSection>
          <Style.TipsTitle>FIFTH TIP</Style.TipsTitle>
          <Style.Tips $textColor={COLORS.CHARCOAL}>
            {retailer.tips.tips}
          </Style.Tips>
          <Style.MoreInfo to={retailer.slug}>More Info</Style.MoreInfo>
        </Style.MoreInfoSection>
      )}
    </Media>
  </Style.Map.Card.Container>
)

interface StationCardProps {
  station: TransitStation
}

export const StationMapCard: FunctionComponent<StationCardProps> = ({
  station,
}) => (
  <Style.Map.Card.Container>
    <Style.Map.Card.Card as="div">
      <Style.Map.Card.Content>
        <Style.Map.Card.Category>{station.category}</Style.Map.Card.Category>
        <Style.Map.Card.Name>{station.name}</Style.Map.Card.Name>
        {station.transitLines?.map(line => (
          <Style.Map.Card.LineLogo
            src={line.logo.file.url}
            key={line.id}
            alt={line.name}
          />
        ))}
      </Style.Map.Card.Content>
    </Style.Map.Card.Card>
  </Style.Map.Card.Container>
)

interface ParkingCardProps {
  parking: ParkingLot
}

export const ParkingMapCard: FunctionComponent<ParkingCardProps> = ({
  parking,
}) => (
  <Style.Map.Card.Container>
    <Style.Map.Card.Card as="div">
      {parking.image && (
        <Style.Map.Card.Image
          fluid={parking.image.fluid}
          alt={parking.image.title}
        />
      )}
      <Style.Map.Card.Content>
        <Style.Map.Card.Category>Parking</Style.Map.Card.Category>
        <Style.Map.Card.Name>{parking.name}</Style.Map.Card.Name>
        <Style.Map.Card.Address>{parking.addressToShow}</Style.Map.Card.Address>
      </Style.Map.Card.Content>
    </Style.Map.Card.Card>
  </Style.Map.Card.Container>
)
