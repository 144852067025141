import Img from "gatsby-image"
import Select from "react-select"
import styled from "styled-components"
import ArrowRight from "../../images/arrow-right.svg"
import Plus from "../../images/plus.svg"
import { COLORS, FONTS, MD_BREAKPOINT, MOBILE_MAX_WIDTH } from "../../styles"
import Button from "../common/button"
import { CTA } from "../common/cta"
import { H5, S1, S2, T2 } from "../common/text"
import { AutoLink } from "../link"

interface MapCardContainerProps {
  highlight?: boolean
  isTop?: boolean
}

export default {
  ArrowRightGreenIcon: styled(ArrowRight)`
    height: 0.5rem;
    fill: ${COLORS.BOTTLE_GREEN};
  `,
  ArrowRightWhiteIcon: styled(ArrowRight)`
    height: 0.5rem;
    fill: ${COLORS.WHITE};
  `,
  MoreInfoSection: styled.div`
    padding-top: 15px;
    padding-right: 15px;
    padding-left: 15px;
  `,
  TipsTitle: styled(S1)``,
  Tips: styled(T2)`
    margin: 5px 0 18px 0;
    width: 305px;
  `,
  MoreInfo: styled(CTA)``,
  TopTicker: {
    Container: styled.div`
      height: fit-content;
      background-color: ${COLORS.HOLLY_GREEN};
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(90vh - 25px);
      }
    `,
  },
  FavoriteTicker: {
    Title: styled(S2)`
      width: 100%;
      background-color: ${COLORS.FANTASY_WHITE};
      text-align: center;
      height: 60px;
      padding: 50px 0px;
      text-transform: uppercase;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        padding-top: 35px;
        padding-bottom: 55px;
      }
    `,
    Container: styled.div`
      height: 31rem;
      background-color: ${COLORS.FANTASY_WHITE};
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        position: relative;
        height: 34rem;
      }
    `,
  },
  Itineraries: {
    Container: styled.div`
      margin: 0 auto;
      padding: 50px 0px 50px 0px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      width: fit-content;
      column-gap: 12px;
      row-gap: 12px;
      @media screen and (max-width: 1740px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @media screen and (max-width: 1330px) {
        grid-template-columns: 1fr 1fr;
      }
      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
      }
      @media screen and (max-width: ${MD_BREAKPOINT}px) {
        padding-top: 0;
      }
    `,
    SeeMoreButton: styled(Button)`
      margin-top: 30px;
      grid-column: 1 / -1;
      width: 100%;
    `,
    Plus: styled(Plus)`
      height: 9px;
      color: inherit;
    `,
  },
  MapComp: styled.div`
    height: 100%;
    width: 100%;
  `,

  Map: {
    root: styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 50px 0px;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        flex-direction: column;
        margin-bottom: 0px;
        padding: 0px;
      }
    `,

    RetailersContainer: styled.div`
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      ::-webkit-scrollbar {
        display: none;
      }
      height: 522px;
      width: 366px;
      margin-left: 1.8125rem;
    `,
    SingleRetailerContainer: styled.div`
      display: flex;
      height: 92px;
      width: 100vw;
    `,
    MultipleRetailerContainer: {
      root: styled.div`
        display: flex;
        flex-direction: column;
        height: 152px;
        width: 100%;
      `,
      ticker: styled.div`
        height: calc(152px - 30px);
        width: 100vw;
        ol {
          bottom: 10px;
          padding-top: 10px;
          border-top: 1px solid ${COLORS.BOTTLE_GREEN};
        }
      `,
      retailersCount: styled(S1)`
        width: 100%;
        height: 30px;
        background-color: ${COLORS.BOTTLE_GREEN};
        display: flex;
        justify-content: center;
        align-items: center;
      `,
    },
    Dropdown: styled(Select)`
      z-index: 2 !important;
      padding-bottom: 10px;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        display: none;
      }
    `,
    Card: {
      Container: styled.div<MapCardContainerProps>`
        display: flex;
        flex-direction: column;
        padding-top: ${props => (props.isTop ? `0px` : `15px`)};
        padding-bottom: 15px;
        border-bottom: 1px solid ${COLORS.HOLLY_GREEN};
        background-color: ${props =>
          props.highlight ? COLORS.SEASHELL : "transparent"};
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
          width: 100%;
          background-color: white;
          padding-top: 0px;
          padding-bottom: 0px;
          border-top: 1px solid ${COLORS.HOLLY_GREEN};
          border-bottom: 0px;
          height: fit-content;
        }
      `,
      Card: styled(AutoLink)`
        display: flex;
        height: fit-content;
        width: fit-content;
        text-decoration: none;
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
          width: 100%;
        }
      `,
      Image: styled(Img)`
        width: 134px;
        height: 80px;
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
          height: 92px;
        }
      `,

      Content: styled.div`
        padding: 0 20px 0 15px;
        width: calc(100% - 134px);
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
          padding-top: 15px;
          margin-bottom: 0;
        }
      `,
      Category: styled(S1)`
        margin-bottom: 2px;
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
          margin-bottom: 0;
        }
      `,
      Name: styled(H5)`
        margin-bottom: 14px;
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
          margin-bottom: 7px;
          font-size: 18px;
          line-height: 1.22;
        }
      `,
      Address: styled(T2)`
        margin-top: 1.5px;
      `,
      LineLogo: styled.img`
        width: 18px;
        height: 18px;
        margin-right: 5px;
      `,
    },

    MapContainer: styled.div`
      width: 780px;
      height: 522px;
      @media screen and (max-width: 1024px) {
        width: 65%;
      }
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        width: 100%;
      }
    `,
  },
}
