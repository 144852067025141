export interface HoursProps {
  sundayOpeningHours: string
  mondayOpeningHours: string
  tuesdayOpeningHours: string
  wednesdayOpeningHours: string
  thursdayOpeningHours: string
  fridayOpeningHours: string
  saturdayOpeningHours: string
}
export function open({
  sundayOpeningHours,
  mondayOpeningHours,
  tuesdayOpeningHours,
  wednesdayOpeningHours,
  thursdayOpeningHours,
  fridayOpeningHours,
  saturdayOpeningHours,
}: HoursProps): string {
  const daysMap = [
    sundayOpeningHours,
    mondayOpeningHours,
    tuesdayOpeningHours,
    wednesdayOpeningHours,
    thursdayOpeningHours,
    fridayOpeningHours,
    saturdayOpeningHours,
  ]

  if (mondayOpeningHours == "24/7") {
    return "Open 24 Hours"
  } else {
    const actualHour = new Date().getUTCHours()
    const actualMonth = new Date().getUTCMonth()
    //Here realHour stands for the NYC time. -5 is the difference between NYC & UTC, +daylightsave for the daylight saving time
    //And 24*() is added to correct time in case midnight is crossed and we're in 2 different days in NYC and UTC.
    const daylightsave = actualMonth > 2 && actualMonth < 11 ? 1 : 0 //daylight saving is applied from march to november, can be refined further
    const realHour =
      actualHour - 5 + daylightsave + 24 * (actualHour - 5 + 1 < 0 ? 1 : 0)
    const realMinutes = new Date().getUTCMinutes()
    const today = new Date().getUTCDay()
    const cleanOpeningHours = daysMap[today].split(" ").join("")
    //We also need the next day since we'll have to display opens at [next day's opening hour]
    let cleanNextOpeningHours = daysMap[today === 6 ? 0 : today + 1]
      .split(" ")
      .join("")
    //In case the retailer is closed two days on the row
    cleanNextOpeningHours =
      cleanNextOpeningHours == "CLOSED"
        ? daysMap[today === 5 ? 0 : today + 2].split(" ").join("")
        : cleanNextOpeningHours

    //Separator used (we notice that two different separators are entered by the client, this causes regex errors)
    const separator: string = (
      cleanOpeningHours + cleanNextOpeningHours
    ).includes("-")
      ? "-"
      : (cleanOpeningHours + cleanNextOpeningHours).includes("—")
      ? "—"
      : "–"
    //Today's opening hour
    let openHour =
      cleanOpeningHours == "CLOSED"
        ? cleanNextOpeningHours
        : cleanOpeningHours.slice(
            0,
            cleanOpeningHours.indexOf(separator) +
              (cleanOpeningHours[cleanOpeningHours.indexOf(separator) - 1] ===
              "M"
                ? -2
                : 0)
          )

    //In case the retailer opens in the afternoon (PM)
    let openHourAfternoon = false
    openHourAfternoon =
      openHourAfternoon ||
      cleanOpeningHours[cleanOpeningHours.indexOf(separator) - 2] === "P"
    openHourAfternoon =
      openHourAfternoon ||
      (cleanOpeningHours[cleanOpeningHours.indexOf(separator) - 1] != "M" &&
        cleanOpeningHours.indexOf("PM") - cleanOpeningHours.indexOf(separator) <
          7)

    let openMinutes = "00"
    let closeMinutes = "00"
    let openNextMinutes = "00"
    if (openHour.includes(":")) {
      openMinutes = openHour.split(":")[1]
      openHour = openHour.split(":")[0]
    }
    let closeHour = cleanOpeningHours.slice(
      cleanOpeningHours.lastIndexOf(separator) + 1,
      cleanOpeningHours.lastIndexOf("PM")
    )
    if (closeHour.includes(":")) {
      closeMinutes = closeHour.split(":")[1]
      closeHour = closeHour.split(":")[0]
    }

    //Tomorrow's opening hour
    let openNextHour = cleanNextOpeningHours.slice(
      0,
      cleanNextOpeningHours.indexOf(separator) +
        (cleanNextOpeningHours[cleanNextOpeningHours.indexOf(separator) - 1] ===
        "M"
          ? -2
          : 0)
    )
    //In case the retailer opens in the afternoon (PM)
    let openNextHourAfternoon = false
    openNextHourAfternoon =
      openNextHourAfternoon ||
      cleanNextOpeningHours[cleanNextOpeningHours.indexOf(separator) - 2] ===
        "P"
    openNextHourAfternoon =
      openNextHourAfternoon ||
      (cleanNextOpeningHours[cleanNextOpeningHours.indexOf(separator) - 1] !=
        "M" &&
        cleanNextOpeningHours.indexOf("PM") -
          cleanNextOpeningHours.indexOf(separator) <
          7)

    if (openNextHour.includes(":")) {
      openNextMinutes = openNextHour.split(":")[1]
      openNextHour = openNextHour.split(":")[0]
    }

    const openAt =
      (parseFloat(openHour) % 12) +
      parseFloat(openMinutes) / 60 +
      (openHourAfternoon ? 12.0 : 0.0)
    const closeAt =
      (parseFloat(closeHour) % 12) + parseFloat(closeMinutes) / 60 + 12.0

    const openValue: string =
      closeHour === "CLOSE"
        ? ""
        : realHour + realMinutes / 60 > closeAt
        ? openNextHour + ":" + openNextMinutes
        : openHour + ":" + openMinutes
    const closeValue: string = closeHour + ":" + closeMinutes

    //Final description of the next closing/opening hour
    const openHourIsPM =
      realHour + realMinutes / 60 > closeAt || closeHour === "CLOSE"
        ? openNextHourAfternoon
        : openHourAfternoon
    let precision: string =
      realHour + realMinutes / 60 < openAt ||
      realHour + realMinutes / 60 > closeAt
        ? openValue === ""
          ? ""
          : " • opens at " + openValue + (openHourIsPM ? " PM" : " AM")
        : " • closes at " + closeValue + " PM"
    //Retailer is closed for thewhole today, we wish to display the next day's opening hour
    if (openValue == "") {
      precision =
        " • opens at " +
        openNextHour +
        ":" +
        openNextMinutes +
        (openNextHourAfternoon ? " PM" : " AM")
      return "Closed" + precision
    }
    //Retailer is open : we display open + closing hour
    if (
      realHour + realMinutes / 60 < closeAt &&
      realHour + realMinutes / 60 > openAt
    ) {
      return "Open" + precision
    }
    //Retailer is closed now: we display close + opening hour (today or tomorrow)
    else {
      return "Closed" + precision
    }
  }
}

export function correctDateFormat(date: string) {
  const separator: string = date.includes("-")
    ? "-"
    : date.includes("—")
    ? "—"
    : "–"

  if (!date.includes(separator)) {
    return date
  }
  const startHour: string = date.split(separator)[0]
  const endHour: string = date.split(separator)[1]
  let startHourCorrect = startHour
  let endHourCorrect = endHour
  if (!startHourCorrect.includes(":")) {
    if (startHourCorrect.includes("M")) {
      startHourCorrect =
        startHourCorrect.slice(0, startHourCorrect.indexOf("M") - 1) +
        ":00" +
        startHourCorrect.slice(
          startHourCorrect.indexOf("M") - 1,
          startHourCorrect.indexOf("M") + 1
        )
    } else {
      startHourCorrect = startHourCorrect + ":00"
    }
  }
  if (!endHourCorrect.includes(":")) {
    if (endHourCorrect.includes("M")) {
      endHourCorrect =
        endHourCorrect.slice(0, endHourCorrect.indexOf("M") - 1) +
        ":00" +
        endHourCorrect.slice(
          endHourCorrect.indexOf("M") - 1,
          endHourCorrect.indexOf("M") + 1
        )
    } else {
      endHourCorrect = endHourCorrect + ":00"
    }
  }

  return startHourCorrect + " –" + endHourCorrect
}

//this function changes string format from "10AM-11AM" to "10:00AM-11:00AM"
//TODO: get the correct format directly from contentful
export function correctDateFormatWrapper(dates: string) {
  if (!dates.includes(",")) {
    return correctDateFormat(dates)
  } else {
    const splitted = dates.split(",")
    const result = splitted.map((date: string) => {
      return correctDateFormat(date)
    })
    return result.join(",")
  }
}

//Filling a reduced/concise version of hours section
