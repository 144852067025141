import styled from "styled-components"
import Plus from "../../images/plus.svg"
import { COLORS, MOBILE_MAX_WIDTH } from "../../styles"
import { T4 } from "./text"
import BuyIcon from "../../images/ticket.svg"
import DownloadIcon from "../../images/download-green.svg"

export default {
  Button: styled(T4)<{ withBackground?: boolean,full:Boolean }>`
    background-color: white;
    width: ${props=>props.full ?'100%':'280px;'}}
    height: 50px;
    cursor: pointer;
    border: 1px solid
      ${props => (props.withBackground ? COLORS.WHITE : COLORS.BOTTLE_GREEN)};
    color: ${props =>
      props.withBackground ? COLORS.WHITE : COLORS.BOTTLE_GREEN};
    background-color: ${props =>
      props.withBackground ? COLORS.HOLLY_GREEN : "none"};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: ${props =>
        props.withBackground ? COLORS.WHITE : COLORS.BOTTLE_GREEN};
      color: ${props =>
        props.withBackground ? COLORS.BOTTLE_GREEN : COLORS.WHITE};
    }
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 100% !important;
    }
  `,
  LeftPlus: styled(Plus)`
    margin-right: 10px;
    height: 10px;
    color: inherit;
  `,
  RightPlus: styled(Plus)`
    margin-left: 10px;
    height: 10px;
    color: inherit;
  `,
  TicketIcon: styled(BuyIcon)`
    margin-right: 10px;
    height: 10px;
    color: inherit;
  `,
  DownloadIcon: styled(DownloadIcon)`
    margin-left: 10px;
    height: 10px;
    color: inherit;
  `,
}
