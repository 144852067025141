import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

interface Props {
  category?: any
  heroImage?: any
  slug?: string
  name?: string
  address?: string
  externalLink?: string
  phoneNumber?: string
  subcategory?: any
  openingHours?: any
}

function RichSnippet({
  category,
  heroImage,
  slug,
  name,
  address,
  externalLink,
  phoneNumber,
  subcategory,
  openingHours,
}: Props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )

  const getType = (category: { name: string }[]) => {
    if (!category) return ""
    if (category[0].name === "Dining") return "Restaurant"
    if (category[0].name === "Shopping") return "Shop"
    return ""
  }

  const image = heroImage
    ? heroImage.file
      ? heroImage.file.url
      : heroImage.fluid.src
    : null

  const servesCuisine =
    subcategory && getType(category) === "Restaurant" ? subcategory[0].name : ""

  const DaysList = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ]

  const parseHour = (openingHoursString: string, option: string) => {
    let hour
    if (option === "open")
      hour = openingHoursString.slice(
        0,
        openingHoursString.indexOf(openingHoursString.includes("–") ? "–" : "-")
      )
    else
      hour = openingHoursString.slice(
        openingHoursString.lastIndexOf(
          openingHoursString.includes("–") ? "–" : "-"
        ) + 1,
        openingHoursString.length
      )

    if (hour.includes("AM") || hour.includes("am") || hour.includes("12")) {
      if ((hour.includes("AM") || hour.includes("am")) && hour.includes("12")) {
        hour = hour.replace(/12/, "0")
      }
      hour = hour.replace(/[^\d:-]/g, "").trim()
      if (!hour.includes(":")) hour = hour + ":00"
    } else {
      hour = hour.replace(/[^\d:-]/g, "").trim()

      if (hour.includes(":")) {
        let hh = hour.slice(0, hour.indexOf(":"))
        const mm = hour.slice(hour.indexOf(":") + 1, hour.length)
        hh = (parseInt(hh) + 12).toString()
        hour = `${hh}:${mm}`
      } else {
        hour = (parseInt(hour) + 12).toString()
        hour = hour + ":00"
      }
    }
    if (hour.length === 4) hour = "0" + hour

    return hour
  }

  const getOpeningHours = (openingHoursString: string, index: number) => {
    const dayOfWeek = DaysList[index]

    if (
      !openingHoursString ||
      openingHoursString === "Closed" ||
      openingHoursString === "CLOSED"
    )
      return {
        "@type": "OpeningHoursSpecification",
        dayOfWeek,
        opens: "00:00",
        closes: "00:00",
      }

    const opens = parseHour(openingHoursString, "open")
    const closes = parseHour(openingHoursString, "close")

    return {
      "@type": "OpeningHoursSpecification",
      dayOfWeek,
      opens,
      closes,
    }
  }

  const getValidHours = () => {
    const invalid = [",", "to Dusk", "VARYING"]
    for (const hour of openingHours) {
      if (hour && invalid.some(str => hour.includes(str))) return ""
    }

    const open24hrs = ["24/7", "Open 24 Hours"]
    for (const hour of openingHours) {
      if (hour && open24hrs.some(str => hour.includes(str)))
        return {
          "@type": "OpeningHoursSpecification",
          dayOfWeek: DaysList,
          opens: "00:00",
          closes: "23:59",
        }
    }

    return openingHours
      .map((day: any, index: any) => getOpeningHours(day, index))
      .filter((day: any) => day)
  }

  const openingHoursSpecification = getValidHours()

  const scriptContent = {
    "@context": "https://schema.org",
    "@type": getType(category),
    "@id": `${site.siteMetadata.siteUrl}/${slug}`,
    address: {
      "@type": "PostalAddress",
      streetAddress: address,
      addressLocality: "New York",
      addressRegion: "NY",
      addressCountry: "US",
    },
    url: externalLink,
    telephone: phoneNumber,
    name,
    image,
    servesCuisine,
    openingHoursSpecification,
  }

  const replacer = (key: any, value: string) => {
    if (value === "") return undefined
    return value
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(scriptContent, replacer)}
      </script>
    </Helmet>
  )
}

export default RichSnippet
